<template>
    <div>
      <loading-overlay :active="Loading" :is-full-page="true" loader="bars" />
      <CCard>
        <CCardHeader class="text-center bg-dark text-white">
          <b>{{$t('label.eirPendingTobeIssuedList')}}</b>
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol sm="12" lg="11">
              <CRow>
                <CCol sm="12" lg="3" xl="3" class="center-field">
                  <CSelect
                    :label="$t('label.yard')"
                    :horizontal="{label:'col-sm-12 col-lg-3', input: 'col-sm-12 col-lg-9'}"
                    addLabelClasses="text-right"                
                    v-model="YardId"
                    :value.sync="YardId"
                    :options="yardOptions"
                  />
                </CCol>
                <CCol sm="12" lg="4" xl="4" class="center-field">
                  <CSelect
                    :label="$t('label.movementType')"
                    :horizontal="{label:'col-sm-12 col-lg-auto', input: 'col-sm-12 col-lg-7'}"
                    addLabelClasses="text-right"                
                    v-model="TpEirId"
                    :options="tpEirOptions"
                  />
                </CCol>
                <CCol sm="12" lg="5">
                  <CInput
                    v-uppercase
                    :horizontal="{ label:'col-sm-12 col-lg-auto', input: 'col-sm-12 col-lg-9'}"
                    v-model="ContainerSearch"
                    :placeholder="$t('label.EnterContainerCode')"
                    :label="$t('label.container')"
                      addLabelClasses="text-right"
                    :maxlength="11"
                  >
                  </CInput>
                </CCol>
              </CRow>
            <CRow>
              <CCol sm="12" lg="3" xl="3" class="center-field">
                <div class="form-group form-row" rol="group">
                    <label class="col-form-label col-sm-12 col-lg-3 pr-1 text-right" style="font-size:13px">{{$t('label.visit')}}</label>
                    <div class="col-sm-12 col-lg-9 col-xl-9 px-0 pr-1 pl-1 input-group">
                        <v-select
                            class="select-adjust"
                            :placeholder="$t('label.select')"
                            v-model="VisitId"
                            :value.sync="VisitId"
                            :options="visitFormatted"
                            :reduce="option => option.Json" 
                            :getOptionLabel="option => option.title"
                        >
                            <template #no-options="{ }">
                                {{$t('label.noResultsFound')}}
                            </template>
                            <template #option="{ Json, title }">
                                {{ title }}<br />
                                <cite v-if="title!=$t('label.select')">
                                    {{ Json.VoyageArrival }} - {{ 'ATA: '+Json.Ata }}
                                </cite>
                            </template>
                        </v-select>
                    </div>
                </div>
              </CCol>
              <CCol sm="12" lg="6">
                <div class="form-group form-row d-flex aling-items-center">
                  <label class="col-form-label col-sm-12 col-lg-2 text-right">{{`${$t('label.date')}`}}</label>
                  <div class="input-group col-sm-12 col-lg-4 mb-2">
                    <vue-datepicker 
                      type="datetime"
                      header
                      :lang="this.$i18n.locale"
                      :editable="false"
                      :clearable="false"
                      format="DD/MM/YYYY HH:mm"
                      placeholder="DD/MM/YYYY HH:mm"
                      time-title-format="DD/MM/YYYY HH:mm"
                      v-model="searchFilterDate.fromDay"
                      :disabled-date="validateDateRange"
                      :append-to-body="false"
                      value-type="format"
                      :show-second="false"
                      class="w-100"
                    >
                      <template #icon-calendar>
                        <div style="display: none"></div>
                      </template>
                    </vue-datepicker>
                  </div>
                  <label class="col-form-label col-sm-12 col-lg-auto text-right mb-2">-</label>
                  <div class="input-group col-sm-12 col-lg-4 mb-2">
                    <vue-datepicker 
                      type="datetime"
                      header
                      :lang="this.$i18n.locale"
                      :editable="true"
                      :clearable="false"
                      format="DD/MM/YYYY HH:mm"
                      placeholder="DD/MM/YYYY HH:mm"
                      time-title-format="DD/MM/YYYY HH:mm"
                      v-model="searchFilterDate.toDay"
                      :disabled-date="validateDateRange"
                      :append-to-body="false"
                      value-type="format"
                      :show-second="false"
                      class="w-100"
                    >
                      <template #icon-calendar>
                        <div style="display: none"></div>
                      </template>
                    </vue-datepicker>
                  </div>
                </div>
              </CCol>
            </CRow>
          </CCol>
          <CCol sm="12" lg="auto" class="d-flex center align-items-start justify-content-end px-2">
            <CButton
              size="sm"
              color="watch"
              v-c-tooltip="{ content: $t('label.search'), placement: 'top-end'}"
              class="mx-2"
              style="padding: 0.15rem 0.4rem;"
              @click="getTransactionList()" 
              getPortActivityList
            >
            <CIcon name="cil-search"/>
            </CButton>
            <CButton
              color="wipe"
              square
              size="sm"
              v-c-tooltip="{ content: $t('label.clearFilters'),placement: 'top-end'}"
              style="padding: 0.15rem 0.4rem;"
              @click="(refreshComponent(), getTransactionList())"
            >
              <CIcon name="cil-brush-alt"/>
            </CButton>
          </CCol>
        </CRow>
        <CRow>
          <CCol sm="12">
            <dataTableExtended
              class="align-center-row-datatable"
              :items="formatedItems"
              :fields="fields"
              column-filter
              :noItemsView="tableText.noItemsViewText"
              :table-filter="tableText.tableFilterText"
              :items-per-page-select="tableText.itemsPerPageText"
              :items-per-page="tableText.itemsPerPage"
              hover
              small
              sorter
              pagination
            > 
              <template #options="{ item }">
                <td class="text-center">
                    <CButton
                    square
                    size="sm"
                    color="watch"
                    class="d-flex align-items-center"
                    @click="toggleEir(item)"
                    v-c-tooltip="{
                        content: $t('label.nuevo')+' '+$t('label.eir'),
                        placement: 'top-start'
                    }"
                    >
                        <CIcon name='cil-list' />
                    </CButton>
                
                </td>
              </template>
            </dataTableExtended>
            <template #Status="{item}">
              <td class="text-center">
                <CBadge :color="getBadge(item.Status)">
                  {{ $t('label.'+item.Status) }}
                </CBadge>
              </td>
            </template>
          </CCol>
        </CRow>
        </CCardBody>
      </CCard>
      <ModalEir 
          :modal.sync="eirForm"
          :title="titleModal"
          :ContainerItem="containerEirItems" 
          @Update-list="mounted"
        >
        </ModalEir>
    </div>
  </template>
  <script>
  
  import GeneralMixin from '@/_mixins/general';
  import UpperCase from '@/_validations/uppercase-directive';
  import { DateFormater} from '@/_helpers/funciones';
  import { mapState } from 'vuex';
  import YardManagement from '@/_mixins/yardManagement';
  import moment from 'moment';
  import Eir from '../../yard-management/container/eir/eir-index';
  import Container from '../../yard-management/container/container-index';
  import ModalEir from '../../yard-management/container/eir/modal-eir';

  
  //DATA-------------------------
  function data() {
    return {
      filtre: false,
      previousDate: '',
      laterDate: '',
      YardId: '',
      VisitId: '',
      VisitName: {},
      VisitList: [],
      TpEirId: '',
      TpEirList: [],
      searchFilterDate:{
          fromDay:"",
          toDay:"",
      },
      ContainerSearch: '',
      items: [],
      eirForm: false,
      Loading: false,
      containerEirItems: {},
      ModalEir: false,
      titleModal: '',
      editEir: false
    }
  }
  
  //methods

  async function mounted(){
    this.Loading = true;
    await this.getYardVisitListEirPending(),
    await this.getTpEirList()
    await this.getTransactionList();
  }

  function toggleEir(item) {
    this.eirForm = true;
    this.editEir = true;
    this.containerEirItems = item;
    this.titleModal = this.$t('label.nuevo')+' '+this.$t('label.eir');
  }

  async function getTransactionList() {

    if(this.ContainerSearch  && (this.searchFilterDate.fromDay == '' || this.searchFilterDate.toDay == '' )){
        this.$notify({
            group: 'container',
            title: '¡Error!',
            text: this.$t('label.dateRangefilterContainer'),
            type: 'error',
        });
        return;
    }else{  
      
      this.Loading = true;
      let dateStart = '';
      let dateEnd = '';
      
      dateStart= this.searchFilterDate.fromDay ? DateFormater.formatDateTimeWithoutSlash(this.searchFilterDate.fromDay) : '';
      dateEnd= this.searchFilterDate.toDay ? DateFormater.formatDateTimeWithoutSlash(this.searchFilterDate.toDay) : '';   
  
      let ReportJson = {
          YardId: this.YardId ?? '',//Id del Patio 
          VisitId: this.VisitId?.VisitId ?? '',//Id de la Visita seleccionada
          TpEirId: this.TpEirId ?? '',//Id del tipo de Eir
          ContainerCode: this.ContainerSearch ?? '',//Id del Contenedor
          DateStart: dateStart,//Fecha desde 
          DateEnd: dateEnd//Fecha hasta (Fecha Actual valor maximo)
      };
  
      await this.$http.post('YardCargoTransaction-list',ReportJson, { root: 'ReportJson'})
          .then(response => {
            this.VisitName = this.VisitId;
            let List = Object.keys(response.data.data).length > 1 ? response.data.data : [];
            this.items = List.length != 0 ? List : [];
            if (this.ContainerSearch&&List.length==0) {
              throw this.$t('label.containerNotFount');
            }
          })
          .catch( err => {
              this.$notify({
                  group: 'container',
                  title: '¡Error!',
                  text: err,
                  type: "error"
              });
          })
          .finally(() => {
            this.Loading = false;
          });
    }
  }
  
  function getDateRange(){
      this.previousDate = DateFormater.formatDateRange(process.env.VUE_APP_START_DATE_IN_PATIO);
      this.laterDate = new Date();
      this.searchFilterDate.fromDay = DateFormater.formatDateTimeWithSlash(moment(this.laterDate).subtract(1, 'M'));
      this.searchFilterDate.toDay =  DateFormater.formatTimeZoneToDateTime(this.laterDate);
  }
  
  async function refreshComponent() {
      this.YardId="";
      this.VisitId= '',
      this.VisitName= {},
      this.TpEirId= '',
      this.searchFilterDate={
          fromDay:"",
          toDay:"",
      },
      this.ContainerSearch= '',
      this.items= [],
      await this.getDateRange();
  }
  
  async function getYardVisitListEirPending() {
    await this.$http.get('YardVisit-list', { CompanyBranchId: this.CompanyBranchId })
      .then(response => {
          this.VisitList = response.data.data ?? [];
      })
      .catch( err => {
          this.$notify({
              group: 'container',
              title: '¡Error!',
              text: err,
              type: "error"
          });
      })
  }

  async function getTpEirList() {
    await this.$http.get('TpEir-list', { Filter: 'ACTIVO' })
      .then(response => {
          this.TpEirList = response.data.data ?? [];
      })
      .catch( err => {
          this.$notify({
              group: 'container',
              title: '¡Error!',
              text: err,
              type: "error"
          });
      })
  }


  //computeds
  
  function fields() {
    return [
      { key: 'options', label: '', _style: 'min-width:45px;', sorter: false, filter: false },
      { key: 'Nro', label: '#', _classes: 'text-center', filter: false },
      { key: "ContainerCode", label: this.$t("label.container"),_style: 'min-width: 130px;',_classes:'text-center',},
      { key: "YardName", label: this.$t("label.yard"),_style: 'min-width: 130px;',_classes:'text-center',},
      { key: "VisitDs", label: this.$t("label.visit"),_style: 'min-width: 180px;',_classes:'text-center',},
      { key: "ShippingLineCode", label: this.$t("label.newShippingLine"),_style: 'min-width: 180px;',_classes:'text-center',},
      { key: "TpCargoDetailCode", label: this.$t("label.type"),_style: 'min-width: 130px;',_classes:'text-center',},
      { key: "EventName", label: this.$t("label.event"),_style: 'min-width: 150px;',_classes:'text-center',},
      { key: "TpTransacEirAcronym", label: this.$t("label.transaction"),_style: 'min-width: 130px;',_classes:'text-center',},
      { key: "TpEirName", label: this.$t("label.movementType"),_style: 'min-width: 180px;',_classes:'text-center',},
      { key: "YardCargoStatusDs", label: this.$t("label.actualStatus"),_style: 'min-width: 150px;', _classes: "text-center text-color-negrita" },
      { key: "ReceptionDateFormated", label: this.$t("label.ReceptionDate"),_style: 'min-width: 180px;', _classes: "text-center" },
      { key: "DispatchDateFormated", label: this.$t("label.dispatchDate"),_style: 'min-width: 180px;', _classes: "text-center" },
      { key: 'DaysInYard', label: this.$t('label.DaysInYard'), _classes: 'text-center', _style: 'min-width: 130px;'},
      { key: "TransaLogin", label: this.$t("label.user"),_style: 'min-width: 130px;', _classes: "text-center" },
      { key: "DeviceCod", label: this.$t("label.device"),_style: 'min-width: 130px;', _classes: "text-center" },
      { key: "FormatedDate", label: this.$t("label.date"), _style: "min-width: 180px",_classes: "text-center" },
    ];
  }
  
  
  
  function formatedItems() {
    let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
    return this.items.map((items) =>
      Object.assign({}, items, {  
        Nro: items.Nro,
        YardCargoStatusDs: items[`YardCargoStatusDs${_lang}`] ?? '',
        TpEirName: items[`TpEirName${_lang}`] ?? '',
        EventName: items[`EventName${_lang}`] ?? '',
        ReceptionDateFormated: items.ReceptionDate ? DateFormater.formatDateTimeWithSlash(items.ReceptionDate) : 'N/A',
        DispatchDateFormated: items.DispatchDate ? DateFormater.formatDateTimeWithSlash(items.DispatchDate) : 'N/A',
        DaysInYard:  items[`DaysInYard${_lang}`] ?? '',
        TransaLogin: items.TransaLogin ?? "N/A",
        Status: items.Status,
        FormatedDate: items.TransaRegDate ? DateFormater.formatDateTimeWithSlash(items.TransaRegDate) : 'N/A',
        _classes: items.Status === "INACTIVO" ? "table-danger" : "",
        _classes: 'color-gradient',
        _style: 'background:'+items.TpTransacEirColor,
      })
    );
  }
  
  function validateDateRange(date) {
    return date < this.previousDate || date > this.laterDate;
  }
  

  function yardOptions(){
    if(this.Yards.length === 0){
      return [{
        value: '', 
        label: this.$t('label.select'),
      }];
    }else{
      var chart = [{
        value: '', 
        label: this.$t('label.select'),
      }]
      this.Yards.map(function(e){
        chart.push({
          value: e.YardId,
          label: e.YardName
        })    
        })
        return chart;
    }
  }

  function visitFormatted(){
    if(this.VisitList.length === 0){
        return [{
            title: this.$t('label.select'),
            value: '',
            Json: {
                VoyageArrival: '',
                Eta: '',
                VisitStatus: '',
            },
        }];
    }else{
        let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
        let chart = [{
            title: this.$t('label.select'),
            value: '',
            Json: {
                VoyageArrival: '',
                Eta: '',
                VisitStatus: '',
            }
        }];

        this.VisitList.map(function(e){
            chart.push({
                title: e.VesselName,
                value: e.VisitId,
                Json: {
                    ...e,
                    VoyageArrival: e.VoyageArrival,
                    Ata: DateFormater.formatDateTimeWithSlash(e.Ata)
                }
            })    
        })
        return chart;
    };
  }

  function tpEirOptions(){
    let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
    if(this.TpEirList.length === 0){
      return [{
        value: '', 
        label: this.$t('label.select'),
      }];
    }else{
      var chart = [{
        value: '', 
        label: this.$t('label.select'),
      }]
      this.TpEirList.map(function(e){
        chart.push({
          value: e.TpEirId,
          label: e[`TpEirName${_lang}`]
        })    
        })
        return chart;
    }
  }


  export default {
    name: 'dispatch-export-list',
    components: {Eir,Container,ModalEir},
    data,
    mounted,
    beforeDestroy () {
      this.$store.state.yardManagement.yardCollapse = 0;
      //this.Loading = false;
      this.eirForm = false;
    },
    mixins: [GeneralMixin, YardManagement],
    methods: {
      mounted,
      toggleEir,
      getTransactionList,
      validateDateRange,
      getDateRange,
      refreshComponent,
      getYardVisitListEirPending,
      getTpEirList
    },
    computed: {
      fields,
      formatedItems,
      yardOptions,
      visitFormatted, 
      tpEirOptions,
      ...mapState({
          yardData: state => state.yardManagement.yardData,
          CompanyBranchId: state => state.auth.branch.CompanyBranchId,
          loading: state => state.yardManagement.loading,
          Yards: (state) => state.auth.branch.YardJson,
      })
    },
    directives: UpperCase,
    watch: {
      VisitId: function (val) {
          if(val?.Ata){
              let ata = DateFormater.formatDateTimeWithoutSlash(val.Ata);
              this.previousDate = DateFormater.formatDateRange(ata);
              this.searchFilterDate.fromDay =val.Ata;
          }
          else{
            this.getDateRange();
          }
      },
    },
  };
  </script>
  <style>
    .Entry-state-yard-header{
        background: #1f3864 !important;
    }
    .Entry-state-yard-header .ag-header-group-text{
        width: 100%;
    }
    .Status-state-yard-header{
        background: #ed7d31 !important;
    }
    .Status-state-yard-header .ag-header-group-text{
        width: 100%;
    }
    .Dispatched-state-yard-header{
        background: #616161 !important;
    }
    .Dispatched-state-yard-header .ag-header-group-text{
        width: 100%;
    }
    .Inventary-state-yard-header{
        background: #5797e6 !important;
    }
    .Inventary-state-yard-header .ag-header-group-text{
        width: 100%;
    }
    .center-cell {
        text-align: center;
        vertical-align: middle;
        user-select: initial;
    }
    .ag-header-group-cell-label, .ag-header-cell-label{
        text-align: center;
        justify-content: center;
        align-items: center;
    }
    .center-cell-especial{
            text-align: center;
            vertical-align: middle;
            user-select: initial;
            -moz-user-select: text;
            -ms-user-select: text;
            -webkit-user-select: text;
    }
    .gb-cell{
        background-color: rgba(0, 0, 0, 0.07);
        text-align: center;
        vertical-align: middle;
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
  </style> 
  <style scoped>
  .Containers-header{
    background: #4e5a70;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
  }
  .Containers-Search{
    background: #ffffff;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  .form-control-Search{
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
    background-clip: padding-box;
    border: 1px solid;
    color: #768192;
    background-color: #fff;
    border-color: #fff;
    border-radius: 10px;
    outline: none;
  }
  .Containers-data{
    margin: 5px;
    padding: 5px 15px;
    background-color: #fff;
    border-radius: 10px;
  }
  </style>

  